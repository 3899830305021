<template>
  <levels v-bind="maxLevels" spin/>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import Levels from '@/components/Visualization/Levels.vue'

import { LevelSet } from '@/models/models'

export default defineComponent({
  name: 'LevelsSpinner',
  components: {
    Levels
  },
  setup() {
    return {
      maxLevels: new LevelSet(10,10,10,10)
    }
  }
})
</script>