import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import buildRouter from './router/build-router'
import watchVisibility from './utils/watch-visibility'
import { buttonBarKey, titleBarKey } from './utils/fixed-bar'
import { setDemoMode } from './utils/demo-mode-flag'
import { makeInjector } from '@drmercer/injector'
import { FixedBarManagerService } from './injection/index'

const params = new URLSearchParams(window.location.search);

if (params.get('demo') !== 'off') {
  setDemoMode(true)
}

const injector = makeInjector()
const router = buildRouter(injector)

//Markup and styles for these bars are in index.html and style.css
const fixedBarManager = injector(FixedBarManagerService)
fixedBarManager.initFixedBar(titleBarKey, '#title-bar', router)
fixedBarManager.initFixedBar(buttonBarKey, '#button-bar', router)

createApp(App, {
    injector
  })
  .directive('on-visible', watchVisibility)
  .use(router)
  .mount('#app')