import { ActivitiesBackend, Backend, EntriesBackend } from "@/models/backends/backends";
import { WipActivity, WipEntry } from "@/models/ephemeral/add-entry";
import { Activity, Entry } from "@/models/models";
import ErrorLog from "./error-log";


class EntriesBackendLogWrapper implements EntriesBackend {
  async hasData(): Promise<boolean> {
    try {
      return await this.inner.hasData()
    }
    catch (e) {
      this.errorLog.logError(e)
      throw e
    }
  }
  async deleteEntry(id: number): Promise<void> {
    try {
      await this.inner.deleteEntry(id)
    }
    catch (e) {
      this.errorLog.logError(e)
      throw e
    }
  }
  async getById(id: number): Promise<Entry> {
    try {
      return await this.inner.getById(id)
    }
    catch (e) {
      this.errorLog.logError(e)
      throw e
    }
  }
  async addEntry(e: WipEntry): Promise<Entry> {
    try {
      return await this.inner.addEntry(e)
    }
    catch (e) {
      this.errorLog.logError(e)
      throw e
    }
  }
  async updateEntry(id: number, e: WipEntry): Promise<Entry> {
    try {
      return await this.inner.updateEntry(id, e)
    }
    catch (e) {
      this.errorLog.logError(e)
      throw e
    }
  }
  async getEntries(count: number, offset: number): Promise<Entry[]> {
    try {
      return await this.inner.getEntries(count, offset)
    }
    catch (e) {
      this.errorLog.logError(e)
      throw e
    }
  }

  constructor(private inner: EntriesBackend, private errorLog: ErrorLog) {}
}

class ActivitiesBackendLogWrapper implements ActivitiesBackend {
  async addActivities(a: WipActivity[]): Promise<Activity[]> {
    try {
      return await this.inner.addActivities(a)
    }
    catch (e) {
      this.errorLog.logError(e)
      throw e
    }
  }

  constructor(private inner: ActivitiesBackend, private errorLog: ErrorLog) {}
}

export class BackendLogWrapper implements Backend {
  async init(): Promise<void> {
    try {
      await this.inner.init()
    }
    catch (e) {
      this.errorLog.logError(e)
      throw e
    }
  }
  activities: ActivitiesBackend;
  entries: EntriesBackend;

  constructor(private inner: Backend, private errorLog: ErrorLog) {
    this.activities = new ActivitiesBackendLogWrapper(inner.activities, errorLog)
    this.entries = new EntriesBackendLogWrapper(inner.entries, errorLog)
  }
}