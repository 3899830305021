const DEMO_MODE_KEY = 'DEMO_MODE_KEY'

const isDemoMode = (): boolean => {
  return !!localStorage.getItem(DEMO_MODE_KEY)
}

const setDemoMode = (val: boolean) => {
  if (val) {
    localStorage.setItem(DEMO_MODE_KEY, DEMO_MODE_KEY)
  }
  else {
    localStorage.removeItem(DEMO_MODE_KEY)
  }
}

export {
  isDemoMode,
  setDemoMode
}