<template>
  <button class="demo-banner centered" @click="exit">
    <p class="text-align-center">Tap here to exit demo mode</p>
  </button>
</template>

<script lang="ts">
import { setDemoMode } from '@/utils/demo-mode-flag'
import { defineComponent } from 'vue'

export default defineComponent({
  setup() {
    const exit = () => {
      console.log("exiting demo mode")
      setDemoMode(false)
      window.location.href = "/?demo=off"
    }
    return {
      exit
    }
  },
})
</script>


<style scoped>
.demo-banner {
  margin: 0;
  border-radius: 0;
  cursor: pointer;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: var(--demo-banner-height);
  padding-left: 16px;
  z-index: 3;
}
</style>