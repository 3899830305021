import Headroom from "headroom.js";
import { Router } from "vue-router";

export default class FixedBarManager {
  private _bars = new Map<string|symbol, Headroom>()

  initFixedBar(key: string|symbol, selector: string, router: Router) {
    const el = document.querySelector(selector)
  
    let unfreezeTimeout = -1
  
    if (!el) throw new Error(`Element '${selector}' was not found`)
  
    const headroom = new Headroom(el, {
      tolerance: {
        up: 2,
        down: 2
      }
    });
  
    router.beforeEach(() => {
      headroom.pin()
      headroom.freeze()
    })
  
    router.afterEach(() => {
      if (unfreezeTimeout > 0) {
        clearTimeout(unfreezeTimeout)
      }
      unfreezeTimeout = setTimeout(() => {
        //This delay is to make sure Headroom doesn't react to vue-router scroll changes
        headroom.unfreeze()
        unfreezeTimeout = -1
      }, 200) as unknown as number
    })
  
    headroom.init()

    this._bars.set(key, headroom)
  }
  getFixedBar(key: string|symbol): Headroom {
    const bar = this._bars.get(key)
    if (bar == null) throw new Error(`Fixed bar with key ${String(key)} not found!`)
    return bar
  }
}