
import { LoadingSynchronizerService, useServiceInjector } from '@/injection';
import { prefersReducedMotion } from '@/utils/accessibility';
import { defineComponent, ref, computed } from 'vue'

//Adapted from https://gist.github.com/gre/1650294
const easeOutCubic = (t: number) => (--t)*t*t+1
const easeInOutQuad = (t: number) => t<.5 ? 2*t*t : -1+(4-2*t)*t

const DPI = window.devicePixelRatio;
export const DURATION = 500;

const l = (level: number) => {
  return [
    0.25,
    0.32,
    0.40,
    0.47,
    0.55,
    0.62,
    0.70,
    0.77,
    0.85,
    0.92
  ][level-1]
}

type LevelInfo = {
  level: number;
  color: string;
  rotation: number;
}

export default defineComponent({
  props: {
    physical: {
      type: Number,
      required: true
    },
    spiritual: {
      type: Number,
      required: true
    },
    mental: {
      type: Number,
      required: true
    },
    social: {
      type: Number,
      required: true
    },
    spin: {
      type: Boolean,
      required: false,
      default: false
    },
    updateFavicon: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  setup(props) {
    const inject = useServiceInjector()

    const loadingSynchronizer = inject(LoadingSynchronizerService)

    const context = ref<CanvasRenderingContext2D | null>(null);
    const style = getComputedStyle(document.documentElement);

    const levels = computed<LevelInfo[]>(() =>[
      {
        level: l(props.physical),
        color: style.getPropertyValue('--color-1'),
        rotation: 0
      },
      {
        level: l(props.spiritual),
        color: style.getPropertyValue('--color-2'),
        rotation: 90
      },
      {
        level: l(props.mental),
        color: style.getPropertyValue('--color-3'),
        rotation: 270
      },
      {
        level: l(props.social),
        color: style.getPropertyValue('--color-4'),
        rotation: 180
      },
    ])

    return {
      loadingSynchronizer,
      baseRotation: 55,
      levels,
      context,
      size : ref(0),
      animationStopped: ref(false),
      icons: (Array.from(document.querySelectorAll('link[rel=icon]'))) as HTMLLinkElement[]
    }
  },
  watch: {
    levels(_: LevelInfo[], old: LevelInfo[]) {
      if (prefersReducedMotion()) {
        this.draw(this.levels);
        return;
      }
      this.animateFrom(old);
    }
  },
  methods: {
    init() {
      const canvas = (this.$refs['vis-canvas'] as HTMLCanvasElement);
      canvas.width = canvas.clientWidth * DPI;
      canvas.height = canvas.clientHeight * DPI;
      const context = canvas.getContext('2d');
      this.context = context;
      const size = Math.min(canvas.height, canvas.width);
      this.size = size;
      this.draw(this.levels)
    },
    draw(levels: LevelInfo[]) {
      const ctx = this.context;

      if (!ctx) return;
      const size = this.size;

      ctx.clearRect(0,0,size,size);
      ctx.lineWidth = 0;
      ctx.globalCompositeOperation = 'screen'

      for (let i = 0; i < levels.length; i++) {
        const c = levels[i];
        const radius = size * c.level / 2;
        ctx.save();
        ctx.translate(size/2, size/2);
        ctx.rotate((c.rotation + this.baseRotation) * Math.PI/180);
        ctx.beginPath();
        ctx.arc(radius - size/2, 0, radius, 0, 2* Math.PI);
        ctx.fillStyle = c.color;
        ctx.fill();
        ctx.restore();
      }
    },
    renderFavicon() {
      const canvas = (this.$refs['vis-canvas'] as HTMLCanvasElement);
      const imgUrl = canvas.toDataURL('image/png');
      this.icons.map(l => l.href = imgUrl);
    },
    animateFrom(old: LevelInfo[]) {
      const start = { value: 0 };
      const anim = (timestamp: number) => {
        if (this.animationStopped) return;
        if (start.value == 0) {
          start.value = timestamp;
        }
        const elapsed = timestamp - start.value;
        const progress = easeOutCubic(Math.min(elapsed/DURATION, 1));
        const levelInfo = old.map((el, i) => ({
          ...el,
          level: el.level + (this.levels[i].level - el.level) * progress
        }));
        this.draw(levelInfo);
        if(elapsed < DURATION) {
          requestAnimationFrame(anim);
        } else if (this.updateFavicon) {
          this.renderFavicon();
        }
      }
      requestAnimationFrame(anim);
    },
    animateInfinite() {
      const duration = DURATION * 6
      const sync = this.loadingSynchronizer
      const anim = (timestamp: number) => {
        if (this.animationStopped) return
        if (sync.offset == 0) {
          sync.offset = timestamp
        }
        const elapsed = timestamp - sync.offset

        const levelInfo = this.levels.map(el => {
          const pointInCycle = (elapsed % duration) / duration
          const phaseShifted = 1 - (pointInCycle + (el.rotation / 360)+0.8) % 1
          const boomeranged = Math.abs(phaseShifted - 0.5) * 2

          return {
            ...el,
            level: (el.level * easeInOutQuad(boomeranged) * 0.6) + 0.4
          }
        })

        this.draw(levelInfo)
        requestAnimationFrame(anim)
      }
      requestAnimationFrame(anim)
    }
  },
  activated() {
    this.init();
    if (this.spin) {
      this.loadingSynchronizer.cancelClear()
      this.animateInfinite();
    }
    window.addEventListener('resize', this.init);
  },
  deactivated() {
    if (this.spin) this.loadingSynchronizer.setToClear()
    this.animationStopped = true;
    window.removeEventListener('resize', this.init);
  },
  mounted() {
    this.init();
    if (this.spin) {
      this.loadingSynchronizer.cancelClear()
      this.animateInfinite();
    }
    window.addEventListener('resize', this.init);
  },
  unmounted() {
    window.removeEventListener('resize', this.init);
  },
  beforeUnmount() {
    if (this.spin) this.loadingSynchronizer.setToClear()
    this.animationStopped = true;
  }
})
