import {
  App,
  inject,
  InjectionKey,
  readonly,
  ref,
  Ref
} from 'vue';
import {
  createRouter,
  NavigationFailure,
  NavigationGuardWithThis,
  NavigationHookAfter,
  RouteLocation,
  RouteLocationNormalizedLoaded,
  RouteLocationRaw,
  Router,
  RouteRecord,
  RouteRecordName,
  RouteRecordRaw,
  RouterOptions,
  RouterScrollBehavior
} from 'vue-router'

const EXTENDED_ROUTER_INJECTION_KEY: InjectionKey<ExtendedRouter> = Symbol()

export class ExtendedRouter implements Router {
  private _r: Router
  private _scrollToTop = false

  private _hasPrevious = ref(false)
  public get hasPreviousRoute() {
    return readonly(this._hasPrevious)
  }

  private _isLoading = ref(true)
  public get isLoading() {
    return readonly(this._isLoading)
  }


  constructor(options: RouterOptions) {

    const scrollBehavior: RouterScrollBehavior = (_to, _from, savedPosition) => {
      if (this._scrollToTop) {
        this._scrollToTop = false;
        return {
          top: 0,
          left: 0
        }
      }
      else if (savedPosition) {
        return savedPosition
      }
      else {
        return {
          top: 0,
          left: 0
        }
      }
    }

    const extOptions = {
      ...options,
      scrollBehavior
    }

    this._r = createRouter(extOptions)
    this.currentRoute = this._r.currentRoute
    this.options = this._r.options

    this._r.beforeEach((_to, from) => {
      this._isLoading.value = true
      this._hasPrevious.value = !!(from && from.name)
    })
    this._r.afterEach(() => {
      this._isLoading.value = false
    })
  }

  readonly currentRoute: Ref<RouteLocationNormalizedLoaded>
  readonly options: RouterOptions

  addRoute(
    parentOrRoute: RouteRecordName | RouteRecordRaw,
    route?: RouteRecordRaw
  ) {
    if (route != null)
      return this._r.addRoute(parentOrRoute as RouteRecordName, route)
    else
      return this._r.addRoute(parentOrRoute as RouteRecordRaw)
  }
  removeRoute(name: RouteRecordName): void {
    this._r.removeRoute(name)
  }
  hasRoute(name: RouteRecordName): boolean {
    return this._r.hasRoute(name)
  }
  getRoutes(): RouteRecord[] {
    return this._r.getRoutes()
  }
  resolve(to: RouteLocationRaw, currentLocation?: RouteLocationNormalizedLoaded): RouteLocation & {
      href: string;
  } {
    return this._r.resolve(to, currentLocation)
  }
  push(to: RouteLocationRaw): Promise<NavigationFailure | void | undefined> {
    return this._r.push(to)
  }
  replace(to: RouteLocationRaw): Promise<NavigationFailure | void | undefined> {
    return this._r.replace(to)
  }
  back(): void {
    this._r.back()
  }
  go(delta: number): void {
    this._r.go(delta)
  }
  beforeResolve(guard: NavigationGuardWithThis<undefined>): () => void {
    return this._r.beforeResolve(guard)
  }
  afterEach(guard: NavigationHookAfter): () => void {
    return this._r.afterEach(guard)
  }
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  onError(handler: (error: any) => any): () => void {
    return this._r.onError(handler)
  }
  isReady(): Promise<void> {
    return this._r.isReady()
  }
  install(app: App): void {
    this._r.install(app)
    app.provide(EXTENDED_ROUTER_INJECTION_KEY, this)
  }
  forward(): void {
    this._r.forward()
  }
  beforeEach(guard: NavigationGuardWithThis<undefined>): () => void {
    return this._r.beforeEach(guard)
  }

  //Custom members and methods
  goBackSafely(alternateRoute: RouteLocationRaw, scrollToTop?: boolean) {
    if (scrollToTop) this._scrollToTop = true;

    if (this._hasPrevious.value) {
      this._r.back();
    }
    else {
      this._r.replace(alternateRoute);
    }
  }
}

export const createExtendedRouter = (options: RouterOptions) => {
  return new ExtendedRouter(options)
}

export const useExtendedRouter = (): ExtendedRouter => {
  const i = inject(EXTENDED_ROUTER_INJECTION_KEY)
  if (i == null) {
    throw new Error("Extended router was not registered!")
  }
  return i
}