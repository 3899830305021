<template>
  <div class="loading-screen">
    <levels-spinner class="loader"/>
    <h1>Loading...</h1>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import LevelsSpinner from '@/components/Visualization/LevelsSpinner.vue'

export default defineComponent({
  components: {
    LevelsSpinner
  }
})
</script>

<style>
.loader {
  width: 20vmin;
  height: 20vmin;
}
.loading-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 0 64px 0;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  background-color: var(--color-bg);
}
</style>