import { Session, User } from '@supabase/supabase-js'

import { AuthHelper, Credentials } from './auth-helper'

export type LoginResult = {
  user: User;
  session: Session | null;
}

const SUPABASE_LOCALSTORAGE_KEY = 'SUPABASE_LOCALSTORAGE_KEY'

class SupabaseAuthHelper implements AuthHelper {
  async register(c: Credentials): Promise<void> {
    const supabase = (await import('../models/backends/backends-concrete/supabase/supabase-db')).default
    const { user, error } = await supabase.client.auth.signUp(c)
    if (error != null) throw error
    if (user == null) throw new Error("Supabase.auth.signUp did not return the user object")
    //We don't set the account type here because they need to confirm their email before they can sign in
  }
  async logIn(c: Credentials): Promise<void> {
    const supabase = (await import('../models/backends/backends-concrete/supabase/supabase-db')).default
    const { user, error } = await supabase.client.auth.signIn(c)
    if (error != null) throw error
    if (user == null) throw new Error("Supabase.auth.signIn did not return the user object")
    await this.markUserLoggedIn(true)
  }
  async logOut(): Promise<void> {
    const supabase = (await import('../models/backends/backends-concrete/supabase/supabase-db')).default
    await supabase.client.auth.signOut()
    await this.markUserLoggedIn(false)
  }
  async getSessionFromUrl(): Promise<string> {
    const supabase = (await import('../models/backends/backends-concrete/supabase/supabase-db')).default
    const { data, error } = await supabase.client.auth.getSessionFromUrl({ storeSession: true })
    if (error != null) throw error
    if (data == null) throw new Error("Supabase.auth.getSessionFromUrl did not return the session object")
    await this.markUserLoggedIn(true)
    return data.access_token
  }
  async updatePassword(token: string, newPassword: string): Promise<void> {
    const supabase = (await import('../models/backends/backends-concrete/supabase/supabase-db')).default
    if (token == null) throw new Error("Invalid auth token")
    const { data, error } = await supabase.client.auth.api
        .updateUser(token, { password : newPassword })
    if (error != null) throw error
    if (data == null) throw new Error("Supabase.auth.getSessionFromUrl did not return the session object")
  }
  async resetPassword(email: string): Promise<void> {
    const supabase = (await import('../models/backends/backends-concrete/supabase/supabase-db')).default
    const { error } = await supabase.client.auth.api.resetPasswordForEmail(email)
    if (error != null) throw error
  }
  async markUserLoggedIn(val: boolean) {
    if (val) {
      localStorage.setItem(SUPABASE_LOCALSTORAGE_KEY, SUPABASE_LOCALSTORAGE_KEY)
    }
    else {
      localStorage.removeItem(SUPABASE_LOCALSTORAGE_KEY)
    }
  }
  async isUserLoggedIn() {
    return !!localStorage.getItem(SUPABASE_LOCALSTORAGE_KEY)
  }
  async isSessionExpired(): Promise<boolean> {
    const supabase = (await import('../models/backends/backends-concrete/supabase/supabase-db')).default
    return (supabase.client.auth.session()?.expires_at ?? 0) < (new Date().getTime() / 1000)
  }
}

export default SupabaseAuthHelper