/* eslint-disable @typescript-eslint/camelcase */

import {
  ActivitiesBackend,
  EntriesBackend,
  Backend
} from "../../backends";

import {
  Activity,
  Entry,
  LevelSet
} from "../../../models";
import { WipActivity, WipEntry } from "@/models/ephemeral/add-entry";
import { SupabaseEntry, SupabaseEntryWithId } from './supabase-model-types'

import supabaseDb from './supabase-db'
import { obfuscate, deobfuscate } from "@/utils/obfuscation"
import { AuthHelper } from "@/auth/auth-helper";

const wipEntryToSbEntry = (e: WipEntry): SupabaseEntry => {
  return {
    user_id: supabaseDb.client.auth.user()?.id,
    l_spiritual: e.levels.spiritual,
    l_physical: e.levels.physical,
    l_mental: e.levels.mental,
    l_social: e.levels.social,
    notes: obfuscate(e.notes),
    datetime: e.datetime
  }
}

function createDateAsUTC(dateStr: Date) {
  const date = new Date(dateStr)
  return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()));
}

const sbEntryToEntry = (sbe: SupabaseEntryWithId, activityIds: number[]): Entry => {
  return {
    levels: new LevelSet(
      sbe.l_physical,
      sbe.l_spiritual,
      sbe.l_mental,
      sbe.l_social,
    ),
    notes: deobfuscate(sbe.notes),
    datetime: createDateAsUTC(sbe.datetime),
    id: sbe.id,
    activities: activityIds
  }
}

class SupabaseEntriesBackend implements EntriesBackend {
  public async hasData(): Promise<boolean> {
    if (this._hasData) return true
    else {
      this._hasData = /* TODO: call supabase */ true
      return this._hasData
    }
  }
  private _hasData = false;

  public async addEntry(e: WipEntry): Promise<Entry> {
    const { data, error } = await supabaseDb.client
      .from<SupabaseEntryWithId>('entries')
      .insert([
        wipEntryToSbEntry(e)
      ])
    if (error || !data) throw error ?? new Error("Nothing returned from addEntry")
    const d = data[0]

    //TODO: save activity IDs, probably in a tran with the entry insert
    return sbEntryToEntry(d, [])
  }
  public async updateEntry(id: number, e: WipEntry): Promise<Entry> {
    const { data, error } = await supabaseDb.client
      .from<SupabaseEntryWithId>('entries')
      .update(wipEntryToSbEntry(e))
      .match({ id: `${id}` })

    if (error || !data) throw error ?? new Error("Nothing returned from updateEntry")
    const d = data[0]

    //TODO: save activity IDs, probably in a tran with the entry update
    return sbEntryToEntry(d, [])
  }
  public async getById(id: number): Promise<Entry> {
    const { data, error } = await supabaseDb.client
      .from<SupabaseEntryWithId>('entries')
      .select()
      .match({ id: '' + id })
      .single()
    if (error || !data) throw error ?? new Error("Nothing returned from getById")
    return sbEntryToEntry(data, [])
  }
  public async getEntries(count: number, offset: number): Promise<Entry[]> {
    const { data, error } = await supabaseDb.client
      .from<SupabaseEntryWithId>('entries')
      .select()
      .order('datetime', { ascending: false })
      .range(offset, offset + count - 1)
    if (error || !data) throw error ?? new Error("Nothing returned from getEntries")
    return data.map(d => sbEntryToEntry(d, []))
  }
  public async deleteEntry(id: number): Promise<void> {
    const { error } = await supabaseDb.client
      .from<SupabaseEntryWithId>('entries')
      .delete()
      .match({ id: '' + id })
    if (error) throw error
  }
}

class SupabaseActivitiesBackend implements ActivitiesBackend {
  private _activities: Activity[] = [];

  private _nextActivityId = 0;

  public async addActivities(a: WipActivity[]): Promise<Activity[]> {
    const activities = a.map((ac: WipActivity) => ({
      ...ac,
      id: this._nextActivityId++
    }))
    this._activities.unshift(...activities)
    return activities
  }
}

export default class SupabaseBackend implements Backend {
  activities: ActivitiesBackend;
  entries: EntriesBackend;

  public async init(): Promise<void> {
    if (await this.authHelper.isSessionExpired()) {
      console.log("refreshing session")
      const { error } = await supabaseDb.client.auth.refreshSession()
      if (error) throw error
    }
  }

  constructor(private authHelper: AuthHelper) {
    this.activities = new SupabaseActivitiesBackend();
    this.entries = new SupabaseEntriesBackend();
  }
}