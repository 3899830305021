
import { defineComponent } from 'vue'

import LevelsSpinner from '@/components/Visualization/LevelsSpinner.vue'

export default defineComponent({
  components: {
    LevelsSpinner
  }
})
