export default class LoadingSynchronizer {
  private _offset = 0;
  private _timeout = -1;
  public get offset(): number {
    return this._offset;
  }
  public set offset(v: number) {
    this._offset = v;
  }
  public setToClear() {
    this._timeout = window.setTimeout(() => {
      this._offset = 0;
    }, 100);
  }
  public cancelClear() {
    if (this._timeout >= 0)
      window.clearTimeout(this._timeout)
  }
}