<template>
  <demo-mode-banner v-if="isDemoMode"/>
  <toasts/>
  <loading-screen v-if="routerLoading"/>
  <router-view v-slot="{ Component }">
    <keep-alive include="log-page">
      <component :is="Component" />
    </keep-alive>
  </router-view>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { Injector } from '@drmercer/injector'

import Toasts from '@/components/general/Toasts.vue'
import DemoModeBanner from '@/components/general/DemoModeBanner.vue'
import LoadingScreen from '@/components/Visualization/LoadingScreen.vue'
import { isDemoMode } from './utils/demo-mode-flag'
import { provideServiceInjector } from './injection'
import { useExtendedRouter } from './router/extended-router'

export default defineComponent({
  components: {
    Toasts,
    DemoModeBanner,
    LoadingScreen
  },
  props: {
    injector: {
      type: () => Object as Injector,
      required: true
    }
  },
  setup(props) {
    const dm = isDemoMode()
    if (dm) {
      document.body.classList.add('demo')
    }

    provideServiceInjector(props.injector)

    const router = useExtendedRouter()

    return {
      isDemoMode: dm,
      routerLoading: router.isLoading
    }
  }
})
</script>
