export type ErrorWithTime = Error & {
  timestamp: Date;
}

export default class ErrorLog {
  constructor() {
    window.onerror = (e: Event | string) => {
      if (e instanceof ErrorEvent) {
        this.logError(
          new Error(
            `window.onError: ErrorEvent '${e.message}' in ${e.filename} (${e.lineno}:${e.colno}). Error: ${e.error}`
          )
        )
      }
      else if (e instanceof Event) {
        this.logError(
          new Error(
            `window.onError: Event '${e.toString()}' at ${e.target}. Full error: ${JSON.stringify(e)}`
          )
        )
      }
      else {
        this.logError(new Error(`window.onError: String '${e}'`))
      }
    }
    // //eslint-disable-next-line @typescript-eslint/no-explicit-any
    // console.error = (e: any, ...rest: any[]) => {
    //   if (e instanceof Error && !rest) {
    //     this.logError(e)
    //   }
    //   else if (e instanceof String && !rest) {
    //     this.logError(new Error(`console.error: String '${e}'`))
    //   }
    //   else if (!rest) {
    //     this.logError(new Error(`console.error: String '${JSON.stringify(e)}'`))
    //   }
    //   else {
    //     this.logError(new Error(`console.error: String '${JSON.stringify([e, ...rest])}'`))
    //   }
    //   console.error(e, ...rest)
    // }
  }
  private _errors: ErrorWithTime[] = [];
  public get errors(): ErrorWithTime[] {
    return this._errors;
  }

  public logError(e: Error) {
    const e2 = (e as ErrorWithTime)
    e2.timestamp = new Date()
    this._errors.push(e2);
  }
}