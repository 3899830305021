import { AuthHelper, Credentials } from "@/auth/auth-helper";

import ErrorLog from "./error-log";

export default class AuthHelperLogWrapper implements AuthHelper {

  constructor(private inner: AuthHelper, private log: ErrorLog) {}
  async markUserLoggedIn(val: boolean): Promise<void> {
    try {
      return this.inner.markUserLoggedIn(val)
    } catch(e) {
      this.log.logError(e)
      throw e
    }
  }
  async isUserLoggedIn(): Promise<boolean> {
    try {
      return await this.inner.isUserLoggedIn()
    } catch(e) {
      this.log.logError(e)
      throw e
    }
  }
  async isSessionExpired(): Promise<boolean> {
    try {
      return await this.inner.isSessionExpired()
    } catch(e) {
      this.log.logError(e)
      throw e
    }
  }

  async register(c: Credentials): Promise<void> {
    try {
      return this.inner.register(c)
    } catch(e) {
      this.log.logError(e)
      throw e
    }
  }
  async logIn(c: Credentials): Promise<void> {
    try {
      return this.inner.logIn(c)
    } catch(e) {
      this.log.logError(e)
      throw e
    }
  }
  async logOut(): Promise<void> {
    try {
      return this.inner.logOut()
    } catch(e) {
      this.log.logError(e)
      throw e
    }
  }
  async getSessionFromUrl(): Promise<string> {
    try {
      return this.inner.getSessionFromUrl()
    } catch(e) {
      this.log.logError(e)
      throw e
    }
  }
  async updatePassword(token: string, newPassword: string): Promise<void> {
    try {
      return this.inner.updatePassword(token, newPassword)
    } catch(e) {
      this.log.logError(e)
      throw e
    }
  }
  async resetPassword(email: string): Promise<void> {
    try {
      return this.inner.resetPassword(email)
    } catch(e) {
      this.log.logError(e)
      throw e
    }
  }
}