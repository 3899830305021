import { createClient, SupabaseClient } from '@supabase/supabase-js'

class SupabaseDb {
  private _client: SupabaseClient | null = null
  public get client(): SupabaseClient {
    if (this._client == null) {
      const supabaseUrl = process.env.VUE_APP_SUPABASE_URL
      const supabaseKey = process.env.VUE_APP_SUPABASE_KEY
      if (!supabaseKey) throw new Error("SUPABASE_KEY environment variable is missing")
      if (!supabaseUrl) throw new Error("SUPABASE_URL environment variable is missing")
      this._client = createClient(supabaseUrl, supabaseKey)
    }
    return this._client
  }
}

export default new SupabaseDb()