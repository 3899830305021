import {
  createWebHistory,
  RouteLocationNormalized,
  RouteRecordRaw,
  NavigationGuard
} from "vue-router";
import {
  isDemoMode,
  setDemoMode
} from "@/utils/demo-mode-flag";
import { Injector } from '@drmercer/injector'
import { AuthHelperService, StoreService } from "@/injection/index";
import { showToast } from "@/components/general/Toasts.vue";
import { createExtendedRouter } from "./extended-router";

const routes: RouteRecordRaw[] = [
  {
    path: "/",
    name: "Home",
    component: () => import("@/views/Home.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/menu",
    name: "Menu",
    component: () => import("@/views/Menu.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/add-entry",
    name: "AddEntry",
    component: () => import("@/views/AddEditEntry.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/edit-entry/:id(\\d+)",
    name: "EditEntry",
    component: () => import("@/views/AddEditEntry.vue"),
    props: (route: RouteLocationNormalized) => ({ existingId: +route.params.id }),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/log",
    name: "Log",
    component: () => import("@/views/Log.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/log-entry/:id(\\d+)",
    name: "LogEntry",
    component: () => import("@/views/LogEntry.vue"),
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/LoginOrRegister.vue"),
    props: (route: RouteLocationNormalized) => ({ next: route.query.next }),
    meta: {
      redirectIfLoggedIn: true
    }
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("@/views/LoginOrRegister.vue"),
    meta: {
      redirectIfLoggedIn: true
    }
  },
  {
    path: "/verify-email",
    name: "VerifyEmail",
    component: () => import("@/views/VerifyEmail.vue"),
    meta: {
      redirectIfLoggedIn: true
    }
  },
  {
    path: "/email-verified",
    name: "EmailVerified",
    component: () => import("@/views/EmailVerified.vue"),
    meta: {
      redirectIfLoggedIn: true
    }
  },
  {
    path: "/new-password",
    name: "NewPassword",
    component: () => import("@/views/NewPassword.vue"),
    meta: {
      redirectIfLoggedIn: true
    }
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    component: () => import("@/views/LoginOrRegister.vue"),
    meta: {
      redirectIfLoggedIn: true
    }
  },
  {
    path: "/check-email",
    name: "ResetPasswordCheckEmail",
    component: () => import("@/views/ResetPasswordCheckEmail.vue"),
    meta: {
      redirectIfLoggedIn: true
    }
  },
  {
    path: "/link-expired",
    name: "LinkExpired",
    component: () => import("@/views/LinkExpired.vue")
  },
  {
    path: "/errors",
    name: "Errors",
    component: () => import("@/views/ErrorLog.vue")
  }
]

const buildRouter = (inject: Injector) => {
  const authHelper = inject(AuthHelperService)

  const r = createExtendedRouter({
    history: createWebHistory(),
    routes
  });

  const checkAuth: NavigationGuard = async (to) => {
    if (to.meta.requiresAuth) {
      const login = { path: '/login', query: { next: to.path }}
      if (!(isDemoMode() || await authHelper.isUserLoggedIn())) {
        showToast("You must log in")
        return login
      }
      else {
        try {
          const store = inject(StoreService)
          await store.init()
          return true
        } catch (e) {
          showToast(e.message)
          return login
        }
      }
    }
    else if (to.meta.redirectIfLoggedIn) {
      const home = { path: '/' }
      if ((isDemoMode() || await authHelper.isUserLoggedIn())) {
        try {
          const store = inject(StoreService)
          await store.init()
          return home
        } catch (e) {
          showToast(e.message)
          return true
        }
      }
      else {
        return true
      }
    }
    else {
      return true
    }
  }

  const checkSupabaseHash: NavigationGuard = (to) => {
    if (to.name === 'Home' && new RegExp("(#|&)error_code=").test(to.hash)) {
      return { path: '/link-expired' }
    }
    if (to.name === 'Home' && new RegExp("(#|&)access_token=").test(to.hash)) {
      setDemoMode(false) //Exit demo mode if we are in it
      const result = new RegExp("(?:#|&)type=([^&]+)").exec(to.hash)
      if (result?.length == 2) {
        const type = result[1]
        if (type === "signup") {
          return { path: `/email-verified${to.hash}` }
        }
        if (type === "recovery") {
          return { path: `/new-password${to.hash}` }
        }
      }
    }
    return true
  }

  r.beforeEach(checkSupabaseHash)
  r.beforeEach(checkAuth)
  return r;
}

export default buildRouter;