import {
  ActivitiesBackend,
  EntriesBackend,
  Backend
} from "../../backends";

import {
  Activity,
  Entry,
  LevelSet
} from "../../../models";

import { WipActivity, WipEntry } from "@/models/ephemeral/add-entry";

import demoEntries from './demo-entries.json'

const randomDelay = (): Promise<void> => {
  return new Promise(res => setTimeout(res, 5000 * Math.random() * Math.random() * Math.random()))
}

const relativeDate = (daysAgo: number, hours: number) => {
  const d = new Date()
  d.setTime(d.getTime() - daysAgo * 24 * 60 * 60 * 1000)
  d.setHours(hours)
  d.setMinutes(Math.floor(Math.random() * 60))
  return d
}

type DemoEntry = {
  notes: string;
  hour: number;
  daysAgo: number;
  levels: number[];
}

class DemoEntriesBackend implements EntriesBackend {
  public async hasData(): Promise<boolean> {
    await randomDelay()
    return this._entries.length > 0
  }
  private _entries: Entry[] = (demoEntries as DemoEntry[]).map((e, i) => ({
    ...e,
    levels: new LevelSet(e.levels[0], e.levels[1], e.levels[2], e.levels[3]),
    datetime: relativeDate(e.daysAgo, e.hour),
    activities: [],
    id: i
  }))
  .filter(e => e.datetime.getTime() < new Date().getTime())
  .sort((a, b) => b.datetime.getTime() - a.datetime.getTime())

  private _nextEntryId = 0

  public async addEntry(e: WipEntry): Promise<Entry> {
    await randomDelay()
    const entry = {
      ...e,
      id: this._nextEntryId,
      activities: e.existingActivities,
      notes: e.notes,
    }
    const index = this._entries.findIndex(n => n.datetime.getTime() < e.datetime.getTime())
    this._entries.splice(index === -1 ? this._entries.length : index, 0, entry)
    this._nextEntryId++
    return entry
  }
  public async updateEntry(id: number, e: WipEntry): Promise<Entry> {
    await randomDelay()
    const entry = {
      ...e,
      id,
      activities: e.existingActivities,
      notes: e.notes,
    }
    const index = this._entries.findIndex(n => n.id === id)
    this._entries[index] = entry
    return entry
  }
  public async getById(id: number): Promise<Entry> {
    await randomDelay()
    const res = this._entries.find(n => n.id === id)
    if (res == null) throw new Error("Not found")
    return res
  }
  public async getEntries(count: number, offset: number): Promise<Entry[]> {
    await randomDelay()
    return this._entries.slice(offset, count + offset)
  }
  public async deleteEntry(id: number): Promise<void> {
    await randomDelay()
    this._entries = this._entries.filter(e => e.id !== id)
  }
}

class DemoActivitiesBackend implements ActivitiesBackend {
  private _activities: Activity[] = []

  private _nextActivityId = 0

  public async addActivities(a: WipActivity[]): Promise<Activity[]> {
    await randomDelay()
    const activities = a.map((ac: WipActivity) => ({
      ...ac,
      id: this._nextActivityId++
    }))
    this._activities.unshift(...activities)
    return activities
  }
}

export default class DemoBackend implements Backend {
  activities: ActivitiesBackend
  entries: EntriesBackend

  public init(): Promise<void> {
    return Promise.resolve()
  }

  constructor() {
    this.activities = new DemoActivitiesBackend()
    this.entries = new DemoEntriesBackend()
  }
}