import SupabaseAuthHelper from '@/auth/supabase-auth'
import AuthHelperLogWrapper from '@/logging/auth-helper-log-wrapper'
import { BackendLogWrapper } from '@/logging/backend-log-wrapper'
import ErrorLog from '@/logging/error-log'
import DemoBackend from '@/models/backends/backends-concrete/demo/demo'
import SupabaseBackend from '@/models/backends/backends-concrete/supabase/supabase'
import { Store } from '@/models/store/store'
import { isDemoMode } from '@/utils/demo-mode-flag'
import FixedBarManager from '@/utils/fixed-bar-manager'
import LoadingSynchronizer from '@/utils/loading-synchronizer'
import { injectable, Injector } from '@drmercer/injector'
import { inject as vueInject, InjectionKey, provide } from 'vue'

export const ErrorLogService = injectable(() => {
  return new ErrorLog()
})

export const AuthHelperService = injectable((inject) => {
  const errorLog = inject(ErrorLogService)
  const supabaseAuthHelper = new SupabaseAuthHelper()
  return new AuthHelperLogWrapper(supabaseAuthHelper, errorLog)
})

const LiveBackendService = injectable((inject) => {
  const authHelper = inject(AuthHelperService)
  const errorLog = inject(ErrorLogService)
  const inner = new SupabaseBackend(authHelper)
  return new BackendLogWrapper(inner, errorLog)
})

const DemoBackendService = injectable((inject) => {
  const errorLog = inject(ErrorLogService)
  const inner = new DemoBackend()
  return new BackendLogWrapper(inner, errorLog)
})

export const LoadingSynchronizerService = injectable(() => {
  return new LoadingSynchronizer()
})

export const FixedBarManagerService = injectable(() => {
  return new FixedBarManager()
})

export const StoreService = injectable((inject) => {
  const backend = isDemoMode() ? inject(DemoBackendService) :  inject(LiveBackendService)
  return new Store(backend)
})

const injectorKey: InjectionKey<Injector> = Symbol()

export const useServiceInjector = () => {
  const i = vueInject(injectorKey) //Note that this is Vue's inject
  if (i == null) throw new Error("Injector not found")
  return i
}

export const provideServiceInjector = (i: Injector) => {
  provide(injectorKey, i)
}