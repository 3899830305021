const rot13 = (str: string) => {
  const orig = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"
  const cipher = "nopqrstuvwxyzabcdefghijklmNOPQRSTUVWXYZABCDEFGHIJKLM"
  return str.replace(/[a-z]/gi, l => cipher[orig.indexOf(l)])
}

const obfuscate = rot13
const deobfuscate = rot13

export {
  obfuscate,
  deobfuscate
}