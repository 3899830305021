import { Directive, DirectiveBinding } from 'vue';

interface WvInfo {
  observer: IntersectionObserver;
  callback: IntersectionObserverCallback;
}

function maybeTeardown({observer}: Partial<WvInfo> = {}) {
  if (observer instanceof IntersectionObserver) {
    observer.disconnect();
  } else if (observer) {
    console.error('Expected observer to be an IntersectionObserver, if defined. Was this instead:', observer);
  }
}

function getInfo(el: HTMLElement): Partial<WvInfo> {
  return (el as any)['wv-info'] || {}; //eslint-disable-line @typescript-eslint/no-explicit-any
}

function setInfo(el: HTMLElement, info: WvInfo|undefined): void {
  (el as any)['wv-info'] = info; //eslint-disable-line @typescript-eslint/no-explicit-any
}

function makeCallback(binding: DirectiveBinding): IntersectionObserverCallback {
  if (typeof binding.value !== 'function') {
      console.warn("v-visibility directive value must be a function");
      return () => {}  //eslint-disable-line @typescript-eslint/no-empty-function
  }
  else {
    const listener = binding.value;
    return (records) => {
      if (records.length < 1) {
        return;
      }
      const [{ isIntersecting }] = records;
      if (isIntersecting) {
        listener()
      }
    }
  }
}

/**
 * Attach this directive to an element and it will call the passed function with VisibilityChangeEvents.
 */
const watchVisibility: Directive = {

  mounted(el, binding) {
    const padded: boolean = binding.modifiers['padded'];

    const callback: IntersectionObserverCallback = makeCallback(binding);
    const observer = new IntersectionObserver(callback, {
      root: el.closest('.watchvisibility-root') ?? undefined,
      rootMargin: padded ? '300px' : undefined,
    });
    observer.observe(el);

    callback(observer.takeRecords(), observer);

    // store on element (tearing down any old one that might still be there)
    maybeTeardown(getInfo(el));
    setInfo(el, {observer, callback});
  },

  unmounted(el) {
    maybeTeardown(getInfo(el));
    setInfo(el, undefined);
  },
};

export default watchVisibility;