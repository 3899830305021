
import { setDemoMode } from '@/utils/demo-mode-flag'
import { defineComponent } from 'vue'

export default defineComponent({
  setup() {
    const exit = () => {
      console.log("exiting demo mode")
      setDemoMode(false)
      window.location.href = "/?demo=off"
    }
    return {
      exit
    }
  },
})
