<template>
  <div :class="['toast', fading ? 'fading' : '']"
       v-if="text"
       @click="dismiss">
    {{text}}
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue'

export class Toast {
  constructor(
    public message: string,
    public duration: number
  ) {}
}

export enum ToastDuration {
  Short = 3000,
  Long = 4500
}

class ToastBus {
  private _handler = (n: Toast) => {
    if (process.env.NODE_ENV === 'development') {
      //eslint-disable-next-line no-console
      console.warn(
        "Attempted to create toast but toasts component was not yet mounted.",
        n
      )
    }
  }
  private _hasHandler = false
  public send(toast: Toast) {
    this._handler(toast)
  }
  public register(handler: (n: Toast) => void) {
    if (this._hasHandler) {
      if (process.env.NODE_ENV === 'development') {
        //eslint-disable-next-line no-console
        console.error("Cannot register second Toasts component")
      }
      return;
    }
    this._handler = handler
    this._hasHandler = true
  }
}

const bus = new ToastBus()

export const showToast = (message: string, duration = ToastDuration.Short) => bus.send(new Toast(message, duration))

export default defineComponent({
  setup() {
    const text = ref('')
    const fading = ref(false)
    const mainTimeoutId = ref(-1)
    const fadeTimeoutId = ref(-1)

    const reset = () => {
      text.value = ''
      fading.value = false
      mainTimeoutId.value = -1
      fadeTimeoutId.value = -1
    }

    const cancel = () => {
      if (mainTimeoutId.value >= 0) {
        window.clearTimeout(mainTimeoutId.value)
        mainTimeoutId.value = -1;
      }
      if (fadeTimeoutId.value >= 0) {
        window.clearTimeout(fadeTimeoutId.value)
        fadeTimeoutId.value = -1
      }
    }

    onMounted(() => {
      bus.register((n) => {
        cancel()
        text.value = n.message
        mainTimeoutId.value = window.setTimeout(reset, n.duration)
        fadeTimeoutId.value = window.setTimeout(
          () => fading.value = true,
          Math.max(0, n.duration - 400)
        )
      })
    })

    const dismiss = () => {
      cancel()
      reset()
    }

    return {
      dismiss,
      text,
      fading
    }
  },
})
</script>

<style scoped>
.toast {
  position: fixed;
  top: 74px;
  left: 16px;
  right: 16px;
  color: var(--color-bg);
  background-color: var(--white);
  z-index: 3;
  padding: 8px 12px;
  border-radius: 4px;
  animation-duration: 400ms;
  animation-name: fadeIn;
  font-size: 18px;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}

.toast.fading {
  animation-name: fadeOut;
}

@keyframes fadeOut {
   0% {opacity: 1;}
   100% {opacity: 0;}
}

@keyframes fadeIn {
   0% {opacity: 0;}
   100% {opacity: 1;}
}
</style>