import { ref, Ref } from 'vue';
import { Backend } from '../backends/backends'
import { WipActivity, WipEntry } from '../ephemeral/add-entry';
import {
  Activity,
  Entry,
  Id
} from '../models'

export class Store {
  readonly mostRecentEntrySync: Ref<Entry | null> = ref(null)
  readonly penultimateEntrySync: Ref<Entry | null> = ref(null)
  readonly hasDataSync: Ref<boolean> = ref(false)
  private _isInitialized = false
  get isInitialized(): boolean {
    return this._isInitialized
  }
  
  public constructor(private backend: Backend) {}
  
  public async init(): Promise<void> {
    if (this._isInitialized) return
    await this.backend.init()
    await this.refreshMostRecentEntries()
    this._isInitialized = true
  }

  public async refreshMostRecentEntries() {
    const lastTwoEntries = await this.backend.entries.getEntries(2, 0)
    if (lastTwoEntries.length > 0) {
      this.mostRecentEntrySync.value = lastTwoEntries[0]
      this.hasDataSync.value = true
    }
    else {
      this.mostRecentEntrySync.value = null
    }
    if (lastTwoEntries.length > 1) {
      this.penultimateEntrySync.value = lastTwoEntries[1]
    }
    else {
      this.penultimateEntrySync.value = null
    }
  }

  public async addEntry(e: WipEntry): Promise<Id> {
    const entry = await this.backend.entries.addEntry(e)

    if (this.mostRecentEntrySync.value == null ||
        this.mostRecentEntrySync.value.datetime.getTime() < e.datetime.getTime()) {
      //Entry replaces most recent
      this.penultimateEntrySync.value = this.mostRecentEntrySync.value
      this.mostRecentEntrySync.value = entry
    }
    else if (this.penultimateEntrySync.value == null ||
             this.penultimateEntrySync.value.datetime.getTime() < e.datetime.getTime()) {
      //Entry replaces penultimate
      this.penultimateEntrySync.value = entry
    }

    this.hasDataSync.value = true
    return entry.id
  }

  public async updateEntry(id: Id, e: WipEntry): Promise<Id> {
    const entry = await this.backend.entries.updateEntry(id, e)

    if (this.mostRecentEntrySync.value?.id === id ||
      this.penultimateEntrySync.value?.id === id ||
      this.penultimateEntrySync.value && this.penultimateEntrySync.value.datetime < entry.datetime) {
      await this.refreshMostRecentEntries()
    }
    return id
  }

  public async addActivities(a: WipActivity[]): Promise<Id[]> {
    return (await this.backend.activities.addActivities(a)).map((ac: Activity) => ac.id)
  }
  public async getEntryById(id: Id) {
    return await this.backend.entries.getById(id)
  }
  public async deleteEntry(id: Id) {
    await this.backend.entries.deleteEntry(id)
    await this.refreshMostRecentEntries()

  }
  public async getEntries(count: number, offset: number) {
    return await this.backend.entries.getEntries(count, offset)
  }
}