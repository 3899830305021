export type Id = number

export interface Entry {
  id: Id;
  datetime: Date;
  activities: Id[];
  notes: string;
  levels: LevelSet;
}

export interface Activity {
  id: Id;
  name: string;
  tags: Id[];
  type: Type;
}

export enum Type {
  Spiritual,
  Social,
  Mental,
  Physical
}

export interface Tag {
  id: Id;
  name: string;
  description: string;
}

export class LevelSet {
  /**
   *
   */
  constructor(
    public physical: number,
    public spiritual: number,
    public mental: number,
    public social: number
  ) {}

  public equals(that: LevelSet): boolean {
    return this.spiritual === that.spiritual &&
          this.mental === that.mental &&
          this.physical === that.physical &&
          this.social === that.social
  }

  public copy(): LevelSet {
    return new LevelSet(
      this.physical,
      this.spiritual,
      this.mental,
      this.social
    )
  }
}